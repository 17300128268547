<template>
  <div>
    <HeroLoader :heros="page.replicator_heros" />
    <ComponentsLoader :components="page.replicator_components" />
  </div>
</template>

<script setup lang="ts">
import type { BlueprintPageFragment } from '#gql'
import type { Enforce } from '~/types/general'

const props = defineProps({
  page: {
    type: Object as () => Enforce<BlueprintPageFragment>,
    required: true,
  },
})

provide('hasHero', props.page?.replicator_heros?.length > 0)
</script>
